/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Menu, Text, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Title, SeparateLine, SeparateLineWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Aktuality"} description={"Co je nového u nás a ve světě kamnařiny"}>
        <Column className="pt--10" name={"blog-oddelovaci-cara"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--l8w" name={"s7q6a6nj5gq"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"paddingRight":1012}} columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":100}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17440/1021ab072ec04c679f50ea94423710f9_s=860x_.png"} url={"https://www.facebook.com/profile.php?id=61553456535181"} use={"url"} href={"https://www.facebook.com/profile.php?id=61553456535181"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/17440/1021ab072ec04c679f50ea94423710f9_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/1021ab072ec04c679f50ea94423710f9_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/1021ab072ec04c679f50ea94423710f9_s=860x_.png 860w, https://cdn.swbpg.com/t/17440/1021ab072ec04c679f50ea94423710f9_s=1400x_.png 1400w, https://cdn.swbpg.com/t/17440/1021ab072ec04c679f50ea94423710f9_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":100}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17440/68d2223b8c734c0598db529f3e5145b2_s=860x_.png"} url={"https://www.instagram.com/kamnarstvi_na_rozkosi/"} use={"url"} href={"https://www.instagram.com/kamnarstvi_na_rozkosi/"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/17440/68d2223b8c734c0598db529f3e5145b2_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/68d2223b8c734c0598db529f3e5145b2_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/68d2223b8c734c0598db529f3e5145b2_s=860x_.png 860w, https://cdn.swbpg.com/t/17440/68d2223b8c734c0598db529f3e5145b2_s=1400x_.png 1400w, https://cdn.swbpg.com/t/17440/68d2223b8c734c0598db529f3e5145b2_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu" menu={true} name={"blog-hlavicka"}>
          
          <Menu style={{"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Text className="text-box ff--2 w--600 swpf--uppercase ls--12" style={{"color":"rgb(0,0,0)"}} content={"<span style=\"font-weight: normal;\">DANIEL VLÁHA +420 776 780 996</span>"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"KAMNÁŘSTVÍ NA ROZKOŠI"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/nabizime"} style={{"maxWidth":125}} target={""} content={"nabízíme"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/cenik"} style={{"maxWidth":125}} target={""} content={"ceník"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/poradna"} style={{"maxWidth":140}} target={""} content={"PORADNA"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/galerie"} style={{"maxWidth":140}} target={""} content={"GALERIE"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/aktuality"} style={{"maxWidth":125}} target={""} content={"aktuality"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/o-nas"} style={{"maxWidth":125}} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/kontakt"} style={{"maxWidth":125}} target={""} content={"kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column name={"blog_cech"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--14" content={"člen Cechu kamnářů České republiky"}>
              </Title>

              <Image className="mt--08" src={"https://cdn.swbpg.com/o/17440/5c02d8768d2342198c276b8957ed27a4.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":60}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"blog-oddelovac-1"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--menu" menu={true} name={"blog-rozcestnik"}>
          
          <Menu >
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--style5 --center">
              
              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kamna"} target={""} content={"KAMNA"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/sporaky"} target={""} content={"SPORÁKY"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kombinovana_topidla"} target={""} content={"KOMBINOVANÁ TOPIDLA"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/krby"} target={""} content={"KRBY"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kominy"} target={""} content={"KOMÍNY"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/speciality"} target={""} content={"SPECIALITY"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <SeparateLine className="pb--10" name={"blog-oddelovac-2"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-1ap6eyc bg--center-h bg--top --parallax pb--60 pt--60" name={"blog-vstup"} layout={"l1"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17440/fe70738d4be048b797039dbed57faf5d_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17440/fe70738d4be048b797039dbed57faf5d_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17440/fe70738d4be048b797039dbed57faf5d_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17440/fe70738d4be048b797039dbed57faf5d_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17440/fe70738d4be048b797039dbed57faf5d_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17440/fe70738d4be048b797039dbed57faf5d_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17440/fe70738d4be048b797039dbed57faf5d_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17440/fe70738d4be048b797039dbed57faf5d_s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800,"backgroundColor":"rgba(255,255,255,1)"}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box mt--08" content={"AKTUALITY"}>
              </Title>

              <Subtitle className="subtitle-box ff--3 mt--02" content={"Kamna nekamna, všechno nás zajímá."}>
              </Subtitle>

              <Text className="text-box" content={"Kamnařině se věnujeme na plný úvazek a kromě stavění kamen také šíříme osvětu mezi laickou veřejností.&nbsp;<br>Zde se s vámi podělíme o novinky ze světa kamen, abyste byli vždy v obraze o aktuálním dění.<br>Nevěřte všemu, co se kde píše. Na nás a Cech kamnářů České republiky se můžete vždy spolehnout a budete mít informace z první ruky.<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 mb--10 mt--10" href={"#blog-obsah"} target={null} content={"JDU ČÍST!"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"blog-oddelovac-3"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--10 pt--10" name={"blog-obsah"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/17440/8e5f780b8164419b8d9159719cdb53a7_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":173}} srcSet={"https://cdn.swbpg.com/t/17440/8e5f780b8164419b8d9159719cdb53a7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17440/8e5f780b8164419b8d9159719cdb53a7_s=660x_.jpg 660w"}>
              </Image>

              <Text className="text-box text-box--justify mt--08" style={{"maxWidth":467}} content={"15.1.2024<br>Cech kamnářů České republiky, jehož jsme hrdými členy, vyhlásil anketu \"Zlatý plamen 2023\". <br><br>Jedná se o soutěž cechovních kamnářů o nejhezčí či nejzajímavější topidlo. Hlasovat budou nejen odborníci, ale i široká veřejnost, tedy vy.&nbsp; Hlasování probíhá ve čtyřech kategoriích - krby, kamna, sporáky a speciální topidla.\n<br><br>I my zde máme svá želízka v ohni, ale v rámci fair play nebudeme prozrazovat jaká. Můžete porovnat soutěžní projekty s našimi realizacemi na webových stránkách nebo s příspěvky na FB. Schválně, jestli je najdete. Hlasovat můžete až do 25.2.2024. Tak prohlížejte, kochejte se, inspirujte se a hlasujte. Kamnářům zdar!<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 btn-box--center mt--10" url={"https://www.cechkamnaru.cz/zlaty-plamen-hlasovani/"} use={"url"} href={"https://www.cechkamnaru.cz/zlaty-plamen-hlasovani/"} target={"_blank"} content={"HLASOVAT ZDE!"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/17440/3de25d51afe945b295c10927595de1c9_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":173}} srcSet={"https://cdn.swbpg.com/t/17440/3de25d51afe945b295c10927595de1c9_s=350x_.png 350w"}>
              </Image>

              <Text className="text-box text-box--justify mt--08" style={{"maxWidth":467}} content={"<br>1.1.2024<br>Jak často zmiňujeme, úkolem správného kamnáře není \"jen\" stavět kamna a opravovat sporáky. Rádi bychom vám k našim službám přidali i nadstavbu v podobě informací ze světa kamnařiny. Abyste věděli, co je kde nového, jaké jsou trendy, ale třeba i to, jaké změny se chytají v legislativě a na co se připravit.<br><br>K tomu nám má pomoci i náš zbrusu nový profil na Facebooku. Přidejte se k nám a budeme si zase o něco blíž.&nbsp;<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 btn-box--center mt--10" url={"https://www.facebook.com/profile.php?id=61553456535181"} use={"url"} href={"https://www.facebook.com/profile.php?id=61553456535181"} target={"_blank"} content={"NA FACEBOOK!"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"blog-oddelovac-4"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-xn7sl8 bg--center --parallax mt--10 pb--80 pt--80" name={"blog-konzultace"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17440/cb68a0d2104d43c1adde59210b4e3acd_r=180__s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--style1 pb--40 pl--20 pr--20 pt--40" style={{"maxWidth":520,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
              
              <Title className="title-box title-box--style1 swpf--uppercase" content={"KAMNÁŘSTVÍ <br>NA ROZKOŠI"}>
              </Title>

              <Text className="text-box mt--16" style={{"color":"rgb(0,0,0)","maxWidth":380}} content={"Rodinné kamnářství na břehu vodní nádrže Rozkoš. Přijďte si k nám pro návrh šitý vám na míru."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--style2 btn-box--filling2 swpf--uppercase mt--16" href={"/kontakt"} target={null} content={"konzultace"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"blog-oddelovac-7"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--menu pb--08 pt--20" menu={true} name={"blog-zapati"}>
          
          <Menu style={{"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Text className="text-box ff--2 w--600 swpf--uppercase ls--12" style={{"color":"rgb(0,0,0)"}} content={"<span style=\"font-weight: normal;\">DANIEL VLÁHA +420 776 780 996</span>"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"kamnářství na rozkoši"}>
              </Subtitle>

              <Subtitle className="subtitle-box ff--3 fs--12 mt--06" content={"člen Cechu kamnářů České republiky"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton className="--hvr2 --filling2">
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--sbtn1 btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"/nabizime"} target={null} content={"NABÍZÍME"}>
                </Button>

                <Button className="btn-box btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"/kontakt"} target={null} content={"KONTAKT"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--center" name={"blog-mesta"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center ff--3 fs--14 swpf--uppercase" content={"NÁCHOD - ČESKÁ SKALICE - NOVÉ MĚSTO NAD METUJÍ - ČERVENÝ KOSTELEC - BROUMOV - TRUTNOV - DOBRUŠKA - OPOČNO - RYCHNOV NAD KNĚŽNOU - POLICE NAD METUJÍ"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--10" name={"blog-oddelovac-7"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pt--0" style={{"paddingBottom":2,"backgroundColor":"var(--color-variable-1)"}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"blog-zapati"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 fs--11" href={"/zasady-ochrany-osobnich-udaju"} target={null} content={"ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ"}>
              </Button>

              <Text className="text-box ff--3 fs--14 mt--06" style={{"color":"rgb(0, 0, 0)"}} content={"Přečtěte si podrobné informace o našich Zásadách ochrany osobních údajů před dalším užíváním těchto stránek.&nbsp;<br>Veškeré zde zveřejněné informace a fotografie jsou majetkem Kamnářství na Rozkoši. Jejich šíření je bez souhlasu majitele zakázáno! Děkujeme, že respektujete autorská práva.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}